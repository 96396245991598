import * as React from "react";
import Helmet from "react-helmet";
import { A1, A3, A4, A5, A7, A8 } from "../components/fonts";
import Container from "../components/container";

const CareersPage = () => {
  return (
    <>
      <Helmet title="Careers" />
      <Container className="bg-wbStone50">
        <div className="col-span-30 md:col-span-15 min-h-half md:min-h-screen bg-team-003 bg-cover"></div>
        <div className="relative col-span-30 md:col-span-15">
          <div className="md:pt-32 pb-6 md:min-h-screen md:flex md:flex-col md:justify-end text-wbSand">
            <A1 className="px-6 pt-6 pb-2">
              Transform <br />
              supply chains.
              <br /> Impact the world.
            </A1>
          </div>
          <div className="md:absolute md:top-0 md:left-0 md:w-full md:h-4/6 lg:h-5/6 md:grid md:content-center">
            <a
              className="px-6 md:mx-auto md:transition md:animate-spin-slow"
              href="/open-roles"
            >
              <A8
                className="
								inline-block md:block
								text-wbSand
								hover:bg-wbSand hover:text-wbStone50
								border-wbSand border-1
								transition transform duration-300
								antialiased
								px-2 py-2 mb-12 md:mb-0
								rounded"
              >
                Jump to open roles
              </A8>
            </a>
          </div>
        </div>
        <div className="col-span-30 w-full md:w-6/12 mx-auto mt-32 text-wbStone">
          <div className="grid px-6 space-y-8 max-w-prose">
            <A4>Our world is not predictable.</A4>
            <A4>Our processes are out of date.</A4>
            <A4>Our planet&apos;s resources are finite.</A4>
            <A4>
              <span className="text-wbSand">
                The raw materials supply chain
              </span>{" "}
              — handling those precious metals, chemicals, and agricultural
              commodities that make our world possible —{" "}
              <span className="text-wbSand">
                {" "}
                is the most in need of transformation.
              </span>
            </A4>
            <A4>
              Commodities are still bought and sold via PDFs, e-mails, and phone
              calls, subject to communication breakdowns and supply chain
              inefficiencies as old as trade itself.
            </A4>
          </div>
        </div>
        <div className="col-span-30 mt-32 mb-8 text-wbSand">
          <A3 className="text-center max-w-4xl xl:max-w-7xl mx-auto px-6 pt-6 pb-2">
            Our mission is to bridge the gaps in the supply chain, making it
            more efficient, resilient, and sustainable.
          </A3>
        </div>
        <div className="col-span-30 mt-32 text-wbSand">
          <div className="flex items-center min-h-screen bg-team-010 bg-cover bg-center">
            <A5 className="text-center px-24 mx-auto">
              We&apos;re looking for people excited by that mission.
            </A5>
          </div>
        </div>
        <div className="col-span-30 w-full md:w-6/12 mx-auto my-32 text-wbStone">
          <div className="grid px-6 space-y-8 max-w-prose">
            <A4>
              We&apos;re a combined team of technologists and commodities
              experts.
            </A4>
            <A4>
              Between the office London and internet connections from coast to
              coast, we teach each other, collaborate closely, and bring every
              team member along for the ride.
            </A4>
            <A4>
              Working at Waybridge means building a unique expertise in
              logistics and technology for yourself, and{" "}
              <span className="text-wbSand">
                building a product that has never been created before.
              </span>
            </A4>
          </div>
        </div>
        <div className="col-span-30 w-full">
          <div className="min-h-screen bg-team-009 bg-cover bg-center"></div>
        </div>
        <div className="col-span-30 md:col-span-15">
          <div className="md:min-h-screen md:flex md:items-center text-wbSand">
            <div className="mx-auto">
              <div className="my-12 border-t border-wbSand max-w-xxs mx-6">
                <A7 className="py-3 max-w-xxs md:max-w-lg">
                  We provide every employee with a yearly stipend to pay for
                  classes, courses, and reading for their own growth.
                </A7>
              </div>
              <div className="mb-12 border-t border-wbSand max-w-xxs mx-6">
                <A7 className="py-3 max-w-xxs md:max-w-lg">
                  Our vacation policy is based on trust and communication, not
                  an arbitrary number of days.
                </A7>
              </div>
              <div className="border-t border-wbSand max-w-xxs mx-6">
                <A7 className="mb-12 md:mb-none pt-3 max-w-xxs md:max-w-lg">
                  The Waybridge team is global and diverse, and we look for
                  cultural contribution — not cultural &quot;fit.&quot;
                </A7>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-30 md:col-span-15 min-h-half md:min-h-screen bg-team-002 bg-cover bg-center"></div>
        <div className="col-span-30 md:col-span-15 min-h-half md:min-h-screen bg-team-008 bg-cover bg-center"></div>
        <div className="relative col-span-30 md:col-span-15">
          <div className="p-6 md:min-h-screen md:flex md:items-center text-wbSand">
            <div className="mx-auto text-center">
              <A1 className="mx-auto text-center pb-24">Join the mission.</A1>
              <A7 className="mx-auto text-center max-w-sm">
                See open roles below, or send us an email at{" "}
                <a
                  className="underline hover:text-wbStone transition duration-300"
                  href="mailto:careers@waybridge.com"
                >
                  careers@waybridge.com
                </a>{" "}
                to tell us about yourself, your skillset, and interest in
                Waybridge.
              </A7>
              <a className="" href="/open-roles">
                <A8
                  className="
								inline-block
								mx-auto
								text-wbSand
								text-center
								hover:bg-wbSand hover:text-wbStone50
								border-wbSand border-1
								transition transform duration-300
								antialiased
								px-2 py-2 mt-12 
								rounded shadow-sm"
                >
                  See open roles
                </A8>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
export default CareersPage;
